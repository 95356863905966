const validator = require("email-validator");
const tlds = require('tlds');

export const valueDoesExist = (value) => {
    return !(value === false || value === "" || value === null || value === undefined);
}

//TODO: make validate email error better to give the users more information
export const validateEmail = (email) => {
    //catch no email and any entry that is not a string
    if(!email) {
        return false
    } else if(email && typeof email !== "string"){
        return false
    }
    let splitEmail = email.split(".");
    let emailEnding = splitEmail[splitEmail.length - 1];
    //validate if proper syntax, also check tlds list to see if it exists
    return !!(email && validator.validate(email) && emailEnding && tlds.indexOf(emailEnding) !== -1);
}

export const getURLParameter = (name) => {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}
