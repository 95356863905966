import firebase from 'firebase/app';
import database from 'firebase/database';
import analytics from 'firebase/analytics';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
  constructor() {
    const app = firebase.initializeApp(config);
    this.db = app.database();
    if(process.env.REACT_APP_MEASUREMENT_ID){
      firebase.analytics();
    }
}

tenantVariables = () => this.db.ref('tenantVariables');
currentGameId = () => this.db.ref('currentGame/id');
rulesRegsVariables = () => this.db.ref('tenantRules');
currentGameActive = () => this.db.ref('currentGame/active');
}
export default Firebase;
