import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import MainApp from './components/mainapp/Home';
import Login from './components/mainapp/login';
import AgeVerification from './components/mainapp/AgeVerification';
import { withFirebase } from './components/Firebase';
import Loading from './components/utils/Loading';
import ReactGA from 'react-ga';

if(process.env.NODE_ENV === "production"){
    ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function logPageView(path) {
    path = path || window.location.pathname;
    if(process.env.NODE_ENV === "production"){
        ReactGA.set({ page: path });
        ReactGA.pageview(path);
    }
}

function AuthenticatedRoute({component: Component, authenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
                if(authenticated === true){
                    return (
                        <Component {...props} {...rest} />
                    )
                } else {
                    return (
                        <Redirect to={{pathname: '/login', state: {from: props.location}}} />
                    )
                }
            }} />
    )
}

class App extends Component {
    constructor(props) {
        super(props)
        this.setCurrentUser = this.setCurrentUser.bind(this);
        const userEmail = sessionStorage.getItem('userEmail') || false;
        this.state = {
            authenticated: !!userEmail,
            variables: {},
            loading: true
        }
    }

    setCurrentUser() {
        const userEmail = sessionStorage.getItem('userEmail') || false;
        this.setState({
            authenticated: !!userEmail
        })
    }

    componentDidMount() {
      let language = "english"
      let splitWindowLocation = window.location.pathname.split('/') || ""
      if(window.location.pathname.split('/').length > 2){
        language = splitWindowLocation[2]
      } else if(window.location.pathname.split('/').length > 1){
        language = splitWindowLocation[1]
      }
      this.loadLanguage(language)
      this.tenantVariablesListener = this.props.firebase.tenantVariables().on('value', snapshot => {
        let variables = {}
        if(snapshot && snapshot.val()){
          variables = snapshot.val();
        }
        this.setState({
          variables: variables,
          loading: false
        });
      });
    }

    componentWillUnmount(){
      this.tenantVariablesListener()
    }

    checkForAgeGate(tenantVariables){
      const variable_age_boolean = tenantVariables.collectBirthday || false;
      if(!variable_age_boolean){
        return true
      }
      const formBirthday = tenantVariables.formBirthday || false;
      let variable_age = tenantVariables.allowedAge || 21;
      variable_age = parseInt(variable_age, 10);
      let user_age = localStorage.getItem('verifiedAge') || false;
      if(isNaN(parseInt(user_age))){
        user_age = false
      } else {
        user_age = parseInt(user_age, 10)
      }
      return (user_age && user_age >= variable_age) || formBirthday;
    }

    async loadLanguage(language){
      let languagesConstants;
      if(language){
        try {
          languagesConstants = await import("./constants/languages/"+language+".js");
        } catch(e) {
          language = process.env.REACT_APP_DEFAULT_LANGUAGE || "en";
          languagesConstants = await import("./constants/languages/"+language+".js");
        }
      } else {
        language = process.env.REACT_APP_DEFAULT_LANGUAGE || "en";
        languagesConstants = await import("./constants/languages/"+language+".js");
      }
      this.setState({
        lanaguageConstants: languagesConstants
      })
    }

    render(){
      if (this.state.loading === true) {
          return (
              <Loading loading={this.state.loading} variables={this.state.variables}/>
          )
      }
      let redirectUrl = `/login`;
      if(this.state.langague){
        redirectUrl += ("/" + this.state.langague);
      }
      return (
          <div style={{ margin: "0 auto"}}>
            <BrowserRouter>
              <Switch>
                <Route
                    strict
                    path="/login"
                    render={(props) => {
                      return <Login authenticated={this.state.authenticated} loadPage={() => logPageView()} setCurrentUser={this.setCurrentUser} stringConstants={this.state.lanaguageConstants} variables={this.state.variables} firebase={this.props.firebase} checkForAgeGate={this.checkForAgeGate} {...props} />
                    }}
                />
                <Route
                    strict
                    path="/age_gate"
                    render={(props) => {
                      return <AgeVerification loadPage={() => logPageView()} stringConstants={this.state.lanaguageConstants} variables={this.state.variables} firebase={this.props.firebase} checkForAgeGate={this.checkForAgeGate} {...props} />
                    }}
                />

                  <AuthenticatedRoute
                      exact
                      path="/"
                      authenticated={this.state.authenticated}
                      variables={this.state.variables}
                      passedEmail={this.state.passedEmail}
                      loadPage={() => logPageView()}
                      checkForAgeGate={this.checkForAgeGate}
                      stringConstants={this.state.lanaguageConstants}
                      firebase={this.props.firebase}
                      component={MainApp} />

                {/*<Route*/}
                {/*    path="/"*/}
                {/*    render={(props) => {*/}
                {/*      return <MainApp stringConstants={this.state.lanaguageConstants} variables={this.state.variables} firebase={this.props.firebase} checkForAgeGate={this.checkForAgeGate} {...props} />*/}
                {/*    }}*/}
                {/*/>*/}

                <Redirect to={redirectUrl}/>
              </Switch>
            </BrowserRouter>
          </div>
      );
  }
}

export default withFirebase(App);
